import documentReady from 'document-ready';

// @ts-expect-error - TS2339 - Property 'ZendeskConfig' does not exist on type 'Window & typeof globalThis'.
const ZendeskConfig = window.ZendeskConfig || {};

documentReady(() => {
  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e: any) {
      return true;
    }
  }

  const csrfTokenElement = document.querySelector('meta[name="csrf-token"]');
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const csrfToken = csrfTokenElement!.attributes.getNamedItem('content')!.value;

  if (!inIframe()) {
    const tags: Array<string> = [];

    tags.push('Silo-id:' + ZendeskConfig.siloId);

    window.zESettings = {
      webWidget: {
        helpCenter: {
          searchPlaceholder: {
            '*': 'Search for a solution',
          },
        },
        authenticate: {
          jwtFn: async function (callback: (jwt: string) => void) {
            const response = await fetch(ZendeskConfig.jwtUrl, {
              method: 'POST',
              body: JSON.stringify({
                type: 'widget',
              }),
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken,
              },
            });
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            const data = await response.json();
            callback(data.jwt);
          },
          chat: {
            jwtFn: async function (callback: (jwt: string) => void) {
              const response = await fetch(ZendeskConfig.jwtUrl, {
                method: 'POST',
                body: JSON.stringify({
                  type: 'visitor_auth',
                }),
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'X-CSRF-Token': csrfToken,
                },
              });
              if (!response.ok) {
                throw new Error(response.statusText);
              }
              const data = await response.json();
              callback(data.jwt);
            },
          },
        },
      },
      chat: {
        departments: {
          enabled: [],
        },
        tags: tags,
      },
    };
  }

  // @ts-expect-error - TS2339 - Property 'zEmbed' does not exist on type 'Window & typeof globalThis'.
  window.zEmbed ||
    (function () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const queue: Array<any> = [];

      // @ts-expect-error - TS2339 - Property 'zEmbed' does not exist on type 'Window & typeof globalThis'.
      window.zEmbed = function () {
        // eslint-disable-next-line prefer-rest-params
        queue.push(arguments);
      };

      // eslint-disable-next-line max-len
      // @ts-expect-error - TS2339 - Property 'zE' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'zE' does not exist on type 'Window & typeof globalThis'. | TS2339 - Property 'zEmbed' does not exist on type 'Window & typeof globalThis'.
      window.zE = window.zE || window.zEmbed;
      // @ts-expect-error - TS2339 - Property 'zendeskHost' does not exist on type 'Document'.
      document.zendeskHost = ZendeskConfig.zendeskHost;
      // @ts-expect-error - TS2339 - Property 'zEQueue' does not exist on type 'Document'.
      document.zEQueue = queue;
    })();

  let hasIdentifiedUser = false;
  const identifyZendeskUser = function () {
    if (hasIdentifiedUser) {
      return;
    }

    window.zE('webWidget', 'prefill', {
      name: {
        value: ZendeskConfig.userName,
        readOnly: true,
      },
      email: {
        value: ZendeskConfig.userEmail,
        readOnly: true,
      },
    });

    window.zE('webWidget', 'identify', {
      name: ZendeskConfig.userName,
      email: ZendeskConfig.userEmail,
      organization: ZendeskConfig.organizationName,
    });

    hasIdentifiedUser = true;
  };

  window.zE('webWidget:on', 'open', identifyZendeskUser);
});
