import { datadogRum } from '@datadog/browser-rum';

if (window.Greenhouse && window.Greenhouse.DatadogRum) {
  datadogRum.init({
    clientToken: window.Greenhouse.DatadogRum.clientToken,
    applicationId: window.Greenhouse.DatadogRum.applicationId,
    site: window.Greenhouse.DatadogRum.site,
    env: window.Greenhouse.DatadogRum.env,
    service: window.Greenhouse.DatadogRum.service,
    version: window.Greenhouse.DatadogRum.version,
    sessionSampleRate: window.Greenhouse.DatadogRum.sampleRate,
    sessionReplaySampleRate:
      window.Greenhouse.DatadogRum.sessionReplaySampleRate,
    /*
     * we are statically hosting the source code distributed by datadog at this path,
     * since the normal installation violates our CSP
     * see public/session_replay_worker.min.js
     * which came from: https://unpkg.com/@datadog/browser-worker@5.21.0/bundle/worker.js
     */
    workerUrl: '/workers/session_replay_worker.min.js',
    traceSampleRate: window.Greenhouse.DatadogRum.tracingSampleRate,
    trackUserInteractions: window.Greenhouse.DatadogRum.trackUserInteractions,
    trackResources: window.Greenhouse.DatadogRum.trackResources,
    trackLongTasks: window.Greenhouse.DatadogRum.trackResources,
    allowedTracingUrls: [
      (url) => {
        // Allow RUM <> APM distributed tracing for all greenhouse domains
        const isGreenhouseDomain = url.match(
          /https:\/\/.*\.greenhouse\.(io|dev)/
        );
        // Additional DD request headers cause CORS issues for renderer CDN
        const isRendererCDN = url.match(
          /(\*-)?job-boards.cdn.greenhouse.(dev|io)/
        );
        return isGreenhouseDomain && !isRendererCDN;
      },
    ],
    compressIntakeRequests: window.Greenhouse.DatadogRum.compressIntakeRequests,
    defaultPrivacyLevel: window.Greenhouse.DatadogRum.defaultPrivacyLevel,
  });

  datadogRum.setGlobalContext(window.Greenhouse.DatadogRum.requestContext);
  datadogRum.setUser({
    id: window.Greenhouse.DatadogRum.requestContext.user_id,
    organization: {
      name: window.Greenhouse.DatadogRum.requestContext['organization.name'],
      id: window.Greenhouse.DatadogRum.requestContext['organization.id'],
    },
  });
}
